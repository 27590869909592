import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import Table from '../../components/Table'
import { PATHOGEN_LABELS_API } from '../../utils/const'
import { useTranslation } from 'react-i18next'
import { group } from 'd3-array'

const SP51Table = ({ regions, region }) => {
  const { t } = useTranslation()
  //const allYears = []
  //let years
  // const wrangleSP51TableData = (regions) => {
  //   const allCategories = []

  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'regionType'
  //       ]

  //       if (!keysToExclude.includes(key) && value !== null) {
  //         let year, category, type
  //         if (key.includes('Baseline')) {
  //           year = t('Baseline (rounded)')
  //           if (key.includes('UnTimely')) {
  //             category = key.slice(0, -16)
  //             type = 'UnTimely'
  //           } else {
  //             category = key.slice(0, -14)
  //             type = 'Timely'
  //           }
  //         } else {
  //           year = key.slice(-4)
  //           if (key.includes('UnTimely')) {
  //             category = key.split('U')[0]
  //             type = 'UnTimely'
  //           } else {
  //             category = key.split('T')[0]
  //             type = 'Timely'
  //           }
  //         }

  //         allCategories.push(category)
  //         allYears.push(year)
  //         return { year, category, type, value }
  //       } else return null
  //     })

  //     .filter((item) => item)

  //   const categories = Array.from(new Set(allCategories))
  //   years = Array.from(new Set(allYears))

  //   const formattedData = categories
  //     .map((category) => {
  //       return years.map((year) => {
  //         const catData = allData
  //           .filter((d) => d.category === category)
  //           .filter((d) => d.year === year)
  //           .reduce((acc, d) => {
  //             acc[d.type] = Math.round(+d.value)

  //             return acc
  //           }, {})
  //         return Object.assign({ year, category }, catData)
  //       })
  //     })
  //     .map((item) => {
  //       return item.reduce((acc, d) => {
  //         acc[d.year] =
  //           d.Timely === -1
  //             ? t('not measured')
  //             : d.Timely === -2
  //             ? t('No data')
  //             : `${d.Timely} ${t('out of')} ${d.Timely + d.UnTimely}`
  //         acc['category'] =
  //           d.category === 'total' ? t('Total') : t(PATHOGEN_LABELS[d.category])

  //         return acc
  //       }, {})
  //     })
  //   return formattedData
  // }

  //const sp51Data = wrangleSP51TableData(regions)
  const BASELINE_YEAR = '2018-2020'
  let yrs = []
  const wrangleSp51TableData = (data) => {
    const dataMap = group(data, (d) => d.DIMENSION2)
    const dataByPathogen = Array.from(dataMap.values())

    const sorted = dataByPathogen.sort((a, b) => (a[0].DIMENSION2 ? 1 : -1)) //put total (i.e. null) at top

    return sorted.map((s) => {
      return s.reduce((acc, d) => {
        yrs.push(d.YEAR)
        acc[d.YEAR ?? 'Baseline'] = `${d.NUMERATOR} ${t('out of')} ${
          d.DENOMINATOR
        } `
        acc['category'] = d.DIMENSION2
          ? t(PATHOGEN_LABELS_API[d.DIMENSION2])
          : t('Total')
        return acc
      }, {})
    })
  }
  //these are coming later in 2024 per Christine
  const missingData = [
    {
      2021: t('no data'),
      2022: t('no data'),
      Baseline: t('no data'),
      category: t(PATHOGEN_LABELS_API['POLIO_VAC'])
    },
    {
      2021: t('no data'),
      2022: t('no data'),
      Baseline: t('no data'),
      category: t(PATHOGEN_LABELS_API['POLIO_WILD'])
    }
  ]
  const sp51Data = wrangleSp51TableData(regions).concat(missingData)
  const years = Array.from(new Set(yrs))
    .sort((a, b) => (a > b ? 1 : -1))
    .filter((item) => item)
  //.map((d) => d ?? t('Baseline (rounded)'))
  const data = React.useMemo(() => sp51Data, [sp51Data])

  //const years = Array.from(new Set(data.map((d) => d.year)))
  const yearHeaders = years?.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })
  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('pathogen'),
        accessor: 'category' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${t('annual avg.')} ${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])
  return (
    <Table
      sp="51"
      data={data}
      columns={columns}
      numYearCols={years.length + 1}
      description={t(
        'A table that lists the number of outbreaks with timely detection and response.'
      )}
    />
  )
}

export default SP51Table
